import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useDataStore } from '../api/DataStore';
import SurveyUpperSection from './survey/UpperSection';
import ResultsUpperSection from './results/UpperSection';
import Survey from './survey/Survey';
import Results from './results/Results';
import BottomBar from './BottomBar';
import EmailDialog from './globalParts/EmailDialog';

const useStyles = makeStyles((theme) => ({
  darkBackground: {
    background: 'url("/assets/bluebg.jpg")',
    zIndex: -1,
  },
  contentContainer: {
    paddingLeft: '2%',
    paddingRight: '2%',
    alignItems: 'center',
  },
  mainBackground: {
    backgroundColor: '#ffffff',
    width:'100%',
    minHeight:'100%',
  },
}));

function MainPage() {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');

  const isSurveySubmitted = useSelector(state => state.data.isSurveySubmitted);

  // Sends answers to the api when they are submitted to the redux state
  const API = useDataStore();
  const questions = useSelector(state => state.data.questions);
  const savedAnswers = useSelector(state => state.data.answers);
  const recommendations = useSelector(state => state.data.recommendations);
  const surveyId = useSelector(state => state.data.surveyId);
  const [answersSent, setAnswersSent] = useState(false);
  useEffect(() => {
    if(Object.keys(savedAnswers).length !== 0 && !answersSent){
      API.sendAnswers(surveyId, {questions: questions, answers: savedAnswers, recommendations: recommendations});
      setAnswersSent(true);
    }
  }, [questions, savedAnswers, surveyId, recommendations, API, answersSent])

  // Adds a participant's email to their entry within the database once submitted to redux
  const participant = useSelector(state => state.data.participant);
  const savedEmail = useSelector(state => state.data.email);
  const captchaValue = useSelector(state => state.data.captchaValue);
  useEffect(() => {
    if(savedEmail !== '' && captchaValue !== '' && captchaValue !== null){
      API.sendEmail(participant, savedEmail, captchaValue);
    }
  }, [savedEmail, participant, API, captchaValue])

  const classes = useStyles();


  return (
    <div className={classes.mainBackground}>
      <div className={classes.darkBackground}>
        {!isSurveySubmitted && !id ? (
            <SurveyUpperSection />
          ) : (
            <ResultsUpperSection />
          )
        }
      </div>
      <div className={classes.contentContainer} id="survey">
        {!isSurveySubmitted && !id ? (
            <Survey />
          ) : (
            <Results />
          )
        }
        <BottomBar />
      </div>
      <EmailDialog />
    </div>
  )
}

export default MainPage;
