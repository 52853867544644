import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import CategoryItem from './CategoryItem';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    alignItems: 'center',
    width: 400,
    animation: 'fadeIn ease 1.5s',
    animationIterationCount: 1,
    animationFillMode: 'forwards',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    height: 32,
    fontFamily: 'Droid Serif',
  },
}));

function Categories() {

  const recommendations = useSelector(state => state.data.recommendations);
  const classes = useStyles();
  
  return (
    <Card elevation={3} className={classes.root}>
      <Typography variant="h5" color="textPrimary" className={classes.header} >
        Categories <Chip label={recommendations.length} style={{marginLeft: 'auto'}}/>
        <br />
      </Typography>
      <Divider />
      <div className={classes.scroll}>
        {recommendations.map((data, index) => (
          <div key={data.title}>
            <CategoryItem data={data} index={index} />
            <Divider style={{width: '80%', marginLeft: '10%'}}/>
          </div>
        ))}
      </div>
    </Card>
  );
}

export default Categories;
