import { Provider } from 'react-redux'
import { store } from '../state/store'
import { DataStore } from '../api/DataStore';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import MainPage from './MainPage'

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: "'Droid Sans', sans-serif",
      fontWeightBold: 700, // Set bold weight for bold text
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          strong: {
            fontWeight: 700, // Ensure <strong> tags are bold
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <DataStore>
          <MainPage />
        </DataStore>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
