import { createSlice } from '@reduxjs/toolkit';
import { calculateRecommendations } from './data.utils.js';

export const initialState = {
  surveyId: '',
  name: '',
  introduction: '',
  start_button_text: '',
  step1: '',
  step2: '',
  step3: '',
  questions: [],
  mappings: [],
  answers: {},
  recommendations: [],
  isSurveySubmitted: false,
  tempEmail: '',
  email: '',
  captchaValue: '',
  participant: null,
  fullRecommendations: [],
  currentAnswers: 0,
  selectedCategory: 0,
  emailFailed: false,
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setSurvey(state, action){
      state.name = action.payload.name;
      state.introduction = action.payload.introduction;
      state.start_button_text = action.payload.start_button_text;
      state.step1 = action.payload.step1;
      state.step2 = action.payload.step2;
      state.step3 = action.payload.step3;
      state.questions = action.payload.questions;
      state.mappings = action.payload.recommendations;
      state.surveyId = action.payload.id;
    },
    setName(state, action){
      state.name = action.payload.name;
    },
    submitSurvey(state, action) {
      state.isSurveySubmitted = true;
      state.answers = action.payload;
      state.recommendations = calculateRecommendations(action.payload, state.mappings);
    },
    setTempEmail(state, action){
      state.tempEmail = action.payload;
    },
    sendEmail(state, action){
      state.email = action.payload.email;
      state.captchaValue = action.payload.captchaValue
    },
    setEmailFailed(state, action){
      state.emailFailed = action.payload
    },
    setParticipant(state, action){
      state.participant = action.payload;
    },
    setFullRecommendations(state, action){
      state.fullRecommendations = action.payload;
      state.recommendations = action.payload;
    },
    currentAnswers(state, action){
      state.currentAnswers = action.payload;
    },
    selectCategory(state, action){
      state.selectedCategory = action.payload;
    },
    nextCategory(state, action){
      state.selectedCategory += 1;
    },
    prevCategory(state, action){
      state.selectedCategory -= 1;
    },
  },
});

export const {
  setSurvey,
  setName,
  submitSurvey,
  setEmailFailed,
  setTempEmail,
  sendEmail,
  setParticipant,
  setFullRecommendations,
  currentAnswers,
  selectCategory,
  nextCategory,
  prevCategory,
} = dataSlice.actions;

export default dataSlice.reducer;
