import axios from 'axios';
import { setEmailFailed, setSurvey, setParticipant, setFullRecommendations, setName } from '../state/reducers/data';
import { setIsLoading } from '../state/reducers/loading';
import { testSurveys } from './testData';

if(process.env.REACT_APP_TEST_DATA !== "false") {console.log("USING TEST DATA!")};

const headers = {
  'Content-Type': 'application/json',
};
axios.defaults.headers = headers;
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL + "/survey/"

export function initialiseAPI(dispatch) {
  axios.interceptors.request.use((config) => {
    dispatch(setIsLoading(true));
    return config;
  });

  axios.interceptors.response.use((response) => {
    dispatch(setIsLoading(false));
    return response;
  });

  return {
    setupSurvey: async () => {
      if(process.env.REACT_APP_TEST_DATA !== "false"){
        dispatch(setSurvey(testSurveys[0]));
        return
      }
      // const response = await axios.get('surveys/');
      // const response = await axios.get('https://health-django-app-zu9j5.ondigitalocean.app/survey/surveys/');
      const response = await axios.get(process.env.REACT_APP_SURVEY_LINK);
      // dispatch(setSurvey(response.data.find(survey => survey.id === process.env.REACT_APP_SURVEY_ID)))
      dispatch(setSurvey(response.data[0]))
    },
    sendAnswers: async (surveyId, results) => {
      const response = await axios.post('participant/', {
        survey: surveyId,
        email: "",
        country: "",
        phone: "",
        results: JSON.stringify(results),
      });
      dispatch(setParticipant(response.data));
    },
    sendEmail: async (participant, email, captchaValue) => {
      await axios.put('participant/' + participant.id + '/', { email: email, id: participant.id });
      try {
        await axios.get('send_link/' + participant.id + '/' + captchaValue);
        dispatch(setEmailFailed(false));
        dispatch(setParticipant(participant))
      } catch (err) {
        dispatch(setEmailFailed(true));
      }
    },
    getParticipantData: async (id) => {
      const response = await axios.get('participant/' + id);
      const surveyResponse = await axios.get('surveys/'+ response.data.survey);
      dispatch(setParticipant(response.data));
      dispatch(setName(surveyResponse.data))
      const results = JSON.parse(response.data.results);
      let fullRecommendations = [];
      for (let r in results.recommendations) {
        let rec = results.recommendations[r];
        let recResponse = await axios.get('full-recommendations/' + rec.id);
        fullRecommendations.push({ ...recResponse.data, score: rec.score, index: parseInt(r)})
      }
      dispatch(setFullRecommendations(fullRecommendations))
    },
  }
}
