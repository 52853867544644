import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  upperSectionContainer: {
    paddingTop: 24,
    padding: 50,
    maxWidth: 1140,
    alignItems: 'center',
    color: '#fff',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  logoShareContainer: {
    height: '110px',
  },
  logo: {
    [theme.breakpoints.down('xs')]: {
      height: 64,
    },
    [theme.breakpoints.up('sm')]: {
      height: 80,
    },
  },
  title: {
    fontFamily: 'Droid Serif',
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 40,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 48,
    },
    fontWeight: 600,
  },
  subtitle: {
    fontFamily: 'Droid Serif',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 18,
    },
    fontWeight: 600,
    color: '#FFC001',
  },
  introAndImageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  introText: {
    marginTop: 30,
    maxWidth: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      maxWidth: 600,
      marginBottom: 20,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
      marginBottom: 20,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
      marginBottom: 0,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 20,
      marginBottom: 0,
    },
  },
  orangeButton: {
    marginTop: 30,
    marginBottom: 40,
    backgroundColor: '#FFC001',
    color: '#000',
    padding: theme.spacing(3),
    textTransform: 'none',
    '&:hover':{
      backgroundColor: '#cf9c02'
    }
  },
  mainpageImage: {
    background: "url('/assets/laptop.png')",
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    marginTop: theme.spacing(4),
    width: 300,
    height: 150,
    [theme.breakpoints.up('md')]: {
      width: 400,
      height: 200,
      marginTop: 0,
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.up('lg')]: {
      width: 400,
      height: 250,
    },
  },
  orangeButton: {
    marginTop: 30,
    marginBottom: 40,
    backgroundColor: '#FFC001',
    color: '#000',
    padding: theme.spacing(3),
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#cf9c02',
    },
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '5%',
    paddingRight: '5%',
    height: 100,
    [theme.breakpoints.down('md')]: {
      marginBottom: 240,
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: 140,
    },
    flex: '1 1 0px',
  },
  card: {
    margin: theme.spacing(2),
    padding: theme.spacing(3),
    width: 280,
    [theme.breakpoints.down('md')]: {
      height: 212,
    },
    [theme.breakpoints.up('lg')]: {
      height: 100,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 1,
  },
  cardHorizontalAlign: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
    height: '90%',
  },
  cardText: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(2),
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  cardImage: {
    height: 48,
    width: 48,
    [theme.breakpoints.up('lg')]: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
  },
}));

function Card(props) {
  const classes = useStyles();

  return (
    <Paper elevation={4} className={classes.card}>
      <div className={classes.cardHorizontalAlign}>
        <img src={props.source} className={classes.cardImage} alt="bullet point" />
        <Typography className={classes.cardText}>
          {parse(props.text.replace('<p>', '').replace('</p>', ''))}
        </Typography>
      </div>
    </Paper>
  );
}

function UpperSection() {
  const name = useSelector((state) => state.data.name);
  const intro = useSelector((state) => state.data.introduction);
  const start_button_text = useSelector((state) => state.data.start_button_text);
  const step1 = useSelector((state) => state.data.step1);
  const step2 = useSelector((state) => state.data.step2);
  const step3 = useSelector((state) => state.data.step3);
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <div className={classes.upperSectionContainer}>
        <div className={classes.logoShareContainer}>
          <a href="http://thinkbluehat.com/" target="_blank" rel="noreferrer" style={{ cursor: 'pointer' }}>
            <img src="BHA-logo-2023.png" alt="BlueHatLogo" className={classes.logo} />
          </a>
        </div>
        <Typography className={classes.title}>{name}</Typography>
        <Typography className={classes.subtitle}>
          Get a free assessment of your technology and product functions. See how you stack up.
        </Typography>
        
        <div className={classes.introAndImageContainer}>
          <Typography className={classes.introText}>
            {parse(intro.replace('<p>', '').replace('</p>', ''))}
          </Typography>
          <div className={classes.mainpageImage} role="img" aria-label="MainPage Image" />
        </div>
        
        {!mobile && (
          <Fab className={classes.orangeButton} variant="extended" href="#survey">
            {start_button_text}
          </Fab>
        )}
      </div>

      <Hidden xsDown>
        <div className={classes.cardContainer}>
          <Card key={1} source="/assets/circle1.svg" text={step1} />
          <Card key={2} source="/assets/circle2.svg" text={step2} />
          <Card key={3} source="/assets/circle3.svg" text={step3} />
        </div>
      </Hidden>
    </div>
  );
}

export default UpperSection;
