import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { nextCategory, prevCategory } from '../../../state/reducers/data.js';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@mui/material/Box';
import ScoreProgress from './ScoreProgress';
import NestedScoreProgress from './NestedScoreProgress';
import EmailForm from './EmailForm';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import parse from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      width: 650,
    },
    animation: 'fadeIn ease 1.5s',
    animationIterationCount: 1,
    animationFillMode: 'forwards',
  },
  flex: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: "center",
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: "row",
  },
  number: {
      backgroundColor: '#EBF3F8',
      color: '#0066A8',
      padding: 5,
      marginRight: 5,
  },
  nestedScoreContainer: {
      display: 'flex',
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      border: '1px solid #eee',
      borderRadius: 8,
      margin: theme.spacing(1),
      padding: theme.spacing(2),
  },
  orangeBox: {
      backgroundColor: '#FEF9F7',
      color: '#ED722E',
      margin: theme.spacing(1),
      padding: theme.spacing(3),
      borderRadius: 10,
      position: 'relative',
      overflow: 'hidden',
  },
  bodyContainer: {
      padding: theme.spacing(1),
      lineHeight: 1.75,
  },
  blueBox: {
      backgroundColor: '#EBF3F8',
      margin: theme.spacing(1),
      padding: theme.spacing(2),
      borderRadius: 10,
  },
  justify: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    backgroundColor: '#0066A8',
    color: '#fff',
    padding: theme.spacing(3),
    textTransform: 'none',
    '&:hover':{
      backgroundColor: '#004477'
    }
  },
  orangeButton: {
    backgroundColor: '#FFC001',
    color: '#000',
    margin: theme.spacing(1),
    padding: theme.spacing(3),
    textTransform: 'none',
    '&:hover':{
      backgroundColor: '#cf9c02'
    }
  },
  subtitle: {
    fontSize: '0.75rem',
    color: '#FFF',
    // marginTop: theme.spacing(0.5),
    textAlign: 'center',
  },
  callToActionPreview: {
    display: '-webkit-box',
    WebkitLineClamp: 8, /* Set the total visible line count */
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    color: '#333',
    position: 'relative',
    fontFamily: theme.typography.fontFamily, // Ensures consistent font
    lineHeight: 1.75,
  },
  callToActionFull: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    color: '#333',
    position: 'relative',
    fontFamily: theme.typography.fontFamily, // Ensures consistent font
    lineHeight: 1.75,

  },
  fadeOverlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '75%', /* Controls the height of the fade */
    background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)',
    pointerEvents: 'none', /* Allows interaction with text */
    fontFamily: theme.typography.fontFamily, // Ensures consistent font
    lineHeight: 1.75,
  },
}));

function BodyCard(props) {
  let data = { ...props.data, conclusion: '' };
  let fullData = props.fullData;

  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const tabletMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const parser = (str) => (str ? parse(str) : "");

  if (data.score >= 90) {
    data.conclusion = data.good;
  } else if (data.score >= data.industry_average) {
    data.conclusion = data.weak;
  } else {
    data.conclusion = data.poor;
  }

  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');

  let nextCategoryTitle;
  let prevCategoryTitle;

  try {
    nextCategoryTitle = data.index < props.maxIndex ? fullData[data.index + 1].title : '';
    prevCategoryTitle = data.index > 0 ? fullData[data.index - 1].title : '';
  } catch { // TODO: seems to occur when shrunk to mobile view for some reason, need to fix
    nextCategoryTitle = '';
    prevCategoryTitle = '';

  }
  
  let pdfCategories;

  // for some reason fullData is undefined on mobile view
  fullData.forEach((category, index) => {
    if (index === fullData.length-1) {
      pdfCategories += "and ";
    }
    if (typeof pdfCategories === "undefined") {
      pdfCategories = `${category.title}`;
    } else {
      pdfCategories += `${category.title}`;
    };
    if (index !== fullData.length-1) {
      pdfCategories += ", ";
    }
  });

  // Function to open the content in a new window for printing with a logo, introductory page, and page numbers
  const printContent = () => {
    const newWindow = window.open('', '_blank');
    
    // Render an introductory page with a logo and message
    const introPage = `
      <div style="page-break-after: always; text-align: center; margin-top: 10vh;">
        <img src="/assets/BH_color_tran-bg_2362.png" alt="Logo" style="width: 100px; margin-bottom: 20px;" />
        <h1>Health Check Report</h1>
        <h3>Provided by Blue Hat Associates</h3>
        <p>This document contains a detailed breakdown of your results for each category of the Health Check survey.</p>
        <div style="text-align: left">
          <p> The categories discussed in this report are as follows: ${pdfCategories}. For each category, we have calculated your score using our evaluation matrix and provided it alongside the Industry Average. Each category details common problems and suggestions on how to improve where applicable. </p>
          <p> If you would like to discuss your results further, please contact <a href="mailto:tim@thinkbluehat.com"> tim@thinkbluehat.com </a> </p>
        </div>
      </div>
    `;

    let combinedContent = introPage;

    fullData.forEach((category, index) => {
      const descriptionHtml = ReactDOMServer.renderToStaticMarkup(parser(category.description));
      const callToActionHtml = ReactDOMServer.renderToStaticMarkup(parser(category.call_to_action));
      const conclusionHtml = ReactDOMServer.renderToStaticMarkup(parser(category.conclusion));

      combinedContent += `
        <div style="page-break-before: always; padding: 40px 20px; margin-bottom: 40px;">
          <h1>${category.title}</h1>
          <div class="score">
            <p>Your Score: ${category.score}</p>
            <p>Industry Average: ${category.industry_average}</p>
          </div>
          <div class="description">${descriptionHtml}</div>
        </div>
      `;
    });

    const content = `
      <html>
        <head>
          <title>Health Check Full Report</title>
          <style>
            @page {
              margin-top: 1in; /* Adjust the value as needed */
              margin-left: 1in;
              margin-right: 1in;
              margin-bottom: 1in;
            }
            
            body {
              font-family: Arial, sans-serif;
              margin: 0;
            }

            h1 {
              color: #333;
              margin-top: 0;
            }

            .score {
              font-size: 18px;
              color: #ED722E;
            }

            .description, .call-to-action, .conclusion {
              margin-top: 10px;
              margin-bottom: 10px;
            }
          </style>
        </head>
        <body>
          ${combinedContent}
        </body>
      </html>
    `;

    newWindow.document.write(content);
    newWindow.document.close();
    newWindow.print();
  };

  return (
      <Card elevation={3} className={classes.root}>
          <div className={classes.header}>
              <Avatar className={classes.number}>
                  {("0" + (data.index + 1)).slice(-2)}
              </Avatar>
              <Typography variant="h5" color="textPrimary" style={{ padding: 8 }}>
                  {data.title}
              </Typography>
          </div>
          {isMobile ? (
              <div className={classes.nestedScoreContainer}>
                  <NestedScoreProgress data={data} />
              </div>
          ) : (
              <div className={classes.flex}>
                  <ScoreProgress value={data.score} color={"orange"} text="Your score:" />
                  <ScoreProgress value={data.industry_average} color={"blue"} text="Industry average:" />
              </div>
          )}

          {id ? 
            (
              <div className={classes.orangeBox}>
                <span className={classes.callToActionFull}>
                    {parser(data.good)}
                </span>
              </div>
            )
          :
            (
              <div className={classes.orangeBox}>
                <span className={classes.callToActionPreview}>
                    {parser(data.good)}
                </span>
                <div className={classes.fadeOverlay} />
              </div>
            )
          }

          <div className={classes.bodyContainer} scroll={"paper"}>
              {id ? (
                  <div>
                      <span className="MuiTypography-root MuiTypography-body1 MuiTypography-colorTextPrimary" style={{ lineHeight: '1.75' }}>
                          {parser(data.description)}
                      </span>
                      <div className={classes.blueBox}>
                          <span className="MuiTypography-root MuiTypography-subtitle1 MuiTypography-colorTextPrimary" style={{ lineHeight: '1.6' }}>
                              {parser(data.call_to_action)}
                          </span>
                          <div className={classes.flex} style={{marginTop: '10px'}}>
                              <Fab className={classes.button} variant="extended">
                                  <a style={{ color: '#fff', textDecoration: 'none' }} href={data.cta_button_link}>
                                      {data.cta_button_text}
                                  </a>
                              </Fab>
                          </div>
                      </div>
                  </div>
              ) : (
                  <EmailForm />
              )}
          </div>
          {!tabletMobile ? (
            <div className={classes.justify}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>
                    {data.index !== 0 ? (
                        <Fab className={classes.button} variant="extended" onClick={() => dispatch(prevCategory())}>
                            <ArrowBackIcon />
                            <Box display="flex" flexDirection="column" alignItems="center" ml={1}>
                                <Typography>Previous</Typography>
                                {prevCategoryTitle && (
                                    <Typography className={classes.subtitle}>{prevCategoryTitle}</Typography>
                                )}
                            </Box>
                        </Fab>
                    ) : (
                        <div />
                    )}
                </div>

                {id ? 
                  <div style={{ flex: 1, display: 'flex', justifyContent: 'center', position: 'absolute', top: '15%', right: '20%' }}>
                      <Fab className={classes.orangeButton} variant="extended" onClick={printContent}>
                          Download PDF
                      </Fab>
                      <Fab className={classes.orangeButton} variant="extended" onClick={() => window.location.href = window.location.origin}>
                          Restart Survey
                      </Fab>
                  </div>
                : <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}> </div>}

                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                    {data.index !== props.maxIndex && (
                        <Fab className={classes.button} variant="extended" onClick={() => dispatch(nextCategory())}>
                            <Box display="flex" flexDirection="column" alignItems="center" mr={1}>
                                <Typography>Next</Typography>
                                {nextCategoryTitle && <Typography className={classes.subtitle}>{nextCategoryTitle}</Typography>}
                            </Box>
                            <ArrowForwardIcon />
                        </Fab>
                    )}
                </div>
            </div>
        ) : id ? 
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center'}}>
              <Fab className={classes.orangeButton} variant="extended" onClick={printContent}>
                  Download PDF
              </Fab>
              <Fab className={classes.orangeButton} variant="extended" onClick={() => window.location.href = window.location.origin}>
                  Restart Survey
              </Fab>
          </div>
        : <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}> </div>}
      </Card>
  );
}

export default BodyCard;
