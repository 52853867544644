import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles((theme) => ({
  flexRow: {
    width: '100%',
    display: 'flex',
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 8,
    '& > * > *': {
      margin: theme.spacing(1),
      border: '1px solid #eee'
    },
  },
  bottomText: {
    fontSize: 12,
    width: '100%',
    justifyContent: "center",
    textAlign: 'center',
  },
  a: {
    textDecoration: 'none',
    color: 'inherit',
    fontWeight: 'bold',
  },
}));

function BottomBar() {
  const classes = useStyles();
  
  return (
    <div>
      <Divider />
      <div className={classes.flexRow}>
        {/* <a rel="noreferrer" target="_blank" href="https://twitter.com/intent/tweet?text=IT%20Health%20Check%20Survey&url=http://health.thinkbluehat.com">
          <IconButton aria-label="Twitter">
            <TwitterIcon />
          </IconButton>
        </a>
        <a rel="noreferrer" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http://health.thinkbluehat.com">
          <IconButton aria-label="Facebook">
            <FacebookIcon />
          </IconButton>
        </a> */}
        {/* <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/sharing/share-offsite/?url=http://health.thinkbluehat.com"> */}
        <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/company/thinkbluehat">
          <IconButton aria-label="LinkedIn">
            <LinkedInIcon />
          </IconButton>
        </a>
      </div>
      <Typography className={classes.bottomText}>
        © Copyright 2024 Blue Hat Associates. All Rights Reserved. <br/>Please read our <a className={classes.a} rel="noreferrer" target="_blank" href="https://www.thinkbluehat.com/privacy/">privacy policy</a>.
      </Typography>
    </div>
  );
}

export default BottomBar;
